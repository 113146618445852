
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
import EditParticipacion from "./edit-participacion.vue";
import PeopleFind from "../../../components/People/people-find.vue";
import History from "../audit/history.vue";
import Campana from "../../../store/entities/OZONE/campana";
import {
  EstadoParticipacion,
  EstadoParticipacionTree,
} from "../../../store/entities/OZONE/estadoParticipacion";
import SubEstadoParticipacion from "../../../store/entities/OZONE/subEstadoParticipacion";
import { defaults } from "js-cookie";

class PageParticipacionRequest extends PageRequest {
  filter: string;
  email: string;
  dNI: string;
  creacionDesde: Date;
  creacionHasta: Date;
}

@Component({
  components: {
    EditParticipacion,
    PeopleFind,
    History,
  },
})
export default class Participacion extends AbpBase {
  //filters
  pagerequest: PageParticipacionRequest = new PageParticipacionRequest();
  creationTime: Date[] = [];

  participacion: Participacion = null;
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  findModalShow: boolean = false;
  historyModalShow: boolean = false;
  anon: boolean = false;
  entityId: number = 0;
  campana: Campana = null;
  estado: EstadoParticipacion = null;
  subEstado: SubEstadoParticipacion = null;
  currentSearch: { key: string; value: string; label: string }[] = [];

  searchDefinition: {}[] = [
    {
      fieldName: "Nombre",
      fieldType: "text",
      fieldBind: "filter",
      value: "",
    },
    {
      fieldName: "Email",
      fieldType: "text",
      fieldBind: "email",
      value: "",
    },
    {
      fieldName: "DNI",
      fieldType: "text",
      fieldBind: "dNI",
      value: "",
    },
    {
      fieldName: "Ekon",
      fieldType: "text",
      fieldBind: "axapta",
      value: "",
    },
    {
      fieldName: "Campaña",
      fieldType: "custom",
      display: "nombre",
      custom: "select-campana",
      fieldBind: "Campana",
      value: this.campana,
    },
    {
      fieldName: "Estado",
      fieldType: "custom",
      display: "nombre",
      custom: "select-estado",
      fieldBind: "Estado",
      value: this.estado,
    },
    {
      fieldName: "Motivo",
      fieldType: "custom",
      display: "nombre",
      custom: "selectSubEstado",
      fieldBind: "SubEstado",
      value: this.subEstado,
    },
    {
      fieldName: "Fecha Creación Desde",
      fieldType: "date",
      fieldBind: "creacionDesde",
      value: "",
    },
    {
      fieldName: "Fecha Creación Hasta",
      fieldType: "date",
      fieldBind: "creacionHasta",
      value: "",
    },
  ];

  pagination: any = {};

  get list() {
    return this.$store.state.participacion.list;
  }
  get loading() {
    return this.$store.state.participacion.loading;
  }
  async create() {
    this.createModalShow = true;
    this.$router.push({ name: "editparticipacion" });
  }
  editRow(item, index, button) {
    this.$store.commit("participacion/edit", item);
    this.edit();
    this.$router.push({ name: "editparticipacion", params: { id: item.id } });
  }
  showHistory(item, index, button) {
    this.entityId = item.id;
    this.historyModalShow = true;
  }

  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L("¿Desea eliminar esta participación?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Sí"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: "participacion/delete",
            data: item,
          });
          await this.getpage();
        }
      });
  }

  edit() {
    this.editModalShow = true;
  }

  pageChange(page: number) {
    this.$store.commit("participacion/setCurrentPage", page);
    this.getpage();
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit("participacion/setPageSize", pagesize);
    this.getpage();
  }
  async getpage() {
    if (this.$store.state.participacion.modal != null) {
      this.currentSearch.length = 0;

      this.$store.state.participacion.modal.forEach((element) => {
        if (element.value) {
          this.pagerequest[element.fieldBind] = element.value;
          this.currentSearch.push({
            label: element.fieldName,
            key: element.fieldBind,
            value:
              element.fieldType === "custom"
                ? element.value[element.display]
                : element.value,
          });
        } else {
          delete this.pagerequest[element.fieldBind];
        }
      });
    }

    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    await this.$store.dispatch({
      type: "participacion/getAll",
      data: this.pagerequest,
    });

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.participacion.totalCount;
    pagination.pageSize = this.$store.state.participacion.pageSize;
    pagination.current = this.$store.state.participacion.currentPage;
    pagination.showSizeChanger = true;
    pagination.pageSizeOptions = ["10", "50", "100"];
    pagination.showTotal = () =>
      `${this.$store.state.participacion.totalCount} participaciones.`;
    this.pagination = pagination;
  }

  get pageSize() {
    return this.$store.state.participacion.pageSize;
  }
  get totalCount() {
    return this.$store.state.participacion.totalCount;
  }
  get currentPage() {
    return this.$store.state.participacion.currentPage;
  }
  set currentPage(page) {
    this.$store.commit("participacion/setCurrentPage", page);
    this.getpage();
  }

  getSearch = async (model) => {
    this.currentSearch.length = 0;

    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
        this.currentSearch.push({
          label: element.fieldName,
          key: element.fieldBind,
          value:
            element.fieldType === "custom"
              ? element.value[element.display]
              : element.value,
        });
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });
    console.log(this.currentSearch);
    await this.getpage();
    this.findModalShow = false;
  };

  async deleteFilter(ixFilter) {
    var filter = this.currentSearch[ixFilter];
    delete this.pagerequest[filter.key];
    (
      this.$refs.peoplefind as Vue & { resetField: (key: string) => void }
    ).resetField(filter.key);
    await this.getpage();
  }
  async reset() {
    (this.$refs.peoplefind as Vue & { reset: () => void }).reset();
    this.currentSearch.length = 0;
  }

  getEstadoColor(estado) {
    switch (estado) {
      case "Válido":
        return "ant-tag-success";
      case "Gestión Contact Center":
        return "ant-tag-secondary";
      case "Inválido":
        return "ant-tag-danger";
      case "Pendiente":
        return "ant-tag-warning";
      default:
        return "ant-tag-primary";
    }
  }

  getSubEstadoColor(estado) {
    switch (estado) {
      case "Válido":
        return "green";
      case "Gestión Contact Center":
        return "blue";
      case "Inválido":
        return "red";
      case "Pendiente":
        return "orange";
      default:
        return "black";
    }
  }

  hideEmail(email) {
    if(!this.anon){
      return email;
    }
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  }

  hideWord(w) {
    if(!this.anon){
      return w;
    }
    if (w.length < 3) return w;
    return w.substring(0, 2) + "*".repeat(w.length - 2);
  }

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("participacion/setPageSize", pagination.pageSize);
    this.$store.commit("participacion/setCurrentPage", pagination.current);
    //this.getpage();

    await this.getpage();
  };

  async created() {
    this.getpage();
  }

  columns = [
    {
      title: this.L("ID"),
      dataIndex: "id",
    },
    {
      title: this.L("Nombre"),
      scopedSlots: { customRender: "func" },
    },
    {
      title: this.L("Estado"),
      scopedSlots: { customRender: "status" },
    },
    {
      title: this.L("Campaña"),
      scopedSlots: { customRender: "campaign" },
    },
    {
      title: this.L("Regalo"),
      dataIndex: "regalo",
      scopedSlots: { customRender: "gift" },
    },
    {
      title: this.L("Fecha Creacion"),
      dataIndex: "creationTime",
      scopedSlots: { customRender: "date" },
    },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ];
}
